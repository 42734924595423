@use 'sass:math';
@import "styles/_variables.scss";

.task_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  padding: $padding-s $padding-s;
  margin-bottom: math.div($margin-s, 2);

  .task_info {
    width: 70%;

    .task_info_item {
      position: relative;
      width: 100%;
      display: flex;
      padding-right: $margin-s;
      margin-bottom: $margin-s;
      font-size: $font-size-s;
      line-height: 16px;
      color: $color-input-label;
      font-weight: $font-weight-medium;

      .description_tooltip {
        display: none;
        position: absolute;
        bottom: 10px;
        left: 15px;
        max-width: 400px;
        padding: $padding-2xs;
        background: rgba($color-cta-orange, 0.6);
        border-radius: 4px;

        &:hover {
          background: rgba($color-cta-orange, 0.6);
        }
      }

      &:hover .description_tooltip {
        display: flex;
      }

      .task_info_item_label {
        font-size: $font-size-xs;
        font-weight: 500;
        line-height: 16px;
        color: $color-input-txt;
        margin-right: $margin-2xs;
        white-space: nowrap;
      }

      .task_info_item_value {
        font-size: $font-size-xs;
        line-height: 16px;
        color: $color-input-label;
        margin-right: $margin-2xs;
        white-space: nowrap;
      }

      .recurrence {
        margin-left: $margin-xs;
        font-size: $font-size-xs;
        line-height: 14px;
        color: $color-gray-40;
      }

      .path {
        font-size: $font-size-xs;
        line-height: 16px;
        color: $color-input-label;

        .asset_name {
          font-size: $font-size-m;
          font-weight: $font-weight-medium;
          line-height: 24px;
          color: $color-input-label;
          margin: $margin-2xs 0;
          cursor: pointer;

          &:hover {
            color: rgba($color-cta-bl-dark, 0.6);
          }
        }
      }
    }

    .task_info_item_small_margin_bottom {
      @extend .task_info_item;
      margin-bottom: $margin-2xs;
    }

    .created {
      width: 100%;
      display: flex;
      font-size: $font-size-xs;
      line-height: 16px;
      color: $color-gray-40;
    }
  }

  .task_actions {
    width: 20%;

    .action_buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: $margin-s;
    }

    .recurrence_active {
      font-size: $font-size-xs;
      background: $color-info;
      color: $color-white;
      border: 1px solid $color-info;
      border-radius: 6px;
      padding: $padding-2xs;
      margin-right: $margin-xs;
      text-align: center;
    }

    .recurrence_pending {
      font-size: $font-size-xs;
      background: $color-cta-orange;
      color: $color-white;
      border: 1px solid $color-cta-orange;
      border-radius: 6px;
      padding: $padding-2xs;
      margin-right: $margin-xs;
      text-align: center;
    }

    .recurrence_finished {
      font-size: $font-size-xs;
      background: $color-light-blue;
      color: $color-txt;
      border: 1px solid $color-white;
      border-radius: 6px;
      padding: $padding-2xs;
      margin-right: $margin-xs;
      text-align: center;
    }

    .recurrence_suspended {
      font-size: $font-size-xs;
      background: $color-grey-700;
      color: $color-white;
      border: 1px solid $color-white;
      border-radius: 6px;
      padding: $padding-2xs;
      margin-right: $margin-xs;
      text-align: center;
    }

    .action_button {
      width: 30px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: $margin-xs;
      cursor: pointer;
    }

    .task_edit, .task_delete  {
      width: auto;
      height: 20px;
    }

    .task_edit path, .task_delete path {
        fill: $color-grey-800;
    }
  }
}
