/* ubuntu */
@font-face {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 300;
  src: url("../styles/fonts/ubuntu/Ubuntu-Light.ttf") format ("truetype");
}

@font-face {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("../styles/fonts/ubuntu/Ubuntu-Regular.ttf") format ("truetype");
}

@font-face {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url("../styles/fonts/ubuntu/Ubuntu-Medium.ttf") format ("truetype"),
    url("../styles/fonts/ubuntu/Ubuntu-MediumItalic.ttf") format ("truetype");
}

@font-face {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  src: url("../styles/fonts/ubuntu/Ubuntu-Bold.ttf") format ("truetype"),
    url("../styles/fonts/ubuntu/Ubuntu-BoldItalic.ttf") format ("truetype");
}
