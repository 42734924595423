@use 'sass:math';
@import "styles/_variables.scss";

.loaderWrapper {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderWrapperAssetsList {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  padding-bottom: $margin-m;
}

.width {
  width: 100%;
  max-width: 100%;
}

.row {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.messageTop {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  height: fit-content;
  font-size: $font-size-xs;
  color: $color-cta-bl-dark;
  padding-top: $padding-s;
}

.messageTop2 {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  height: fit-content;
  font-size: $font-size-xs;
  color: $color-cta-bl-dark;
  padding-top: $padding-2xs;
  padding-bottom: $padding-s;
}

.message {
  width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  height: fit-content;
  font-size: $font-size-xs;
  color: $color-cta-bl-dark;
  padding-bottom: $padding-s;
}

.red {
  color: $color-error;
}

.modal {
  flex-basis: 876px;
}

.add_txt {
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-cta-orange;
  cursor: pointer;
}

.alert_dropdown {
  width: 190px;
}

.width_dropdown {
  width: 190px;
  margin-bottom: $margin-l;
}

.width_dropdown2 {
  @extend .width_dropdown;
  margin-left: 20px;
}

.number_section {
  padding-left: math.div($padding-m, 2);
}

.asset_number {
  display: flex;
  align-items: center;
  background: $color-grey-400;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  color: $color-input-txt;
  outline: none;
  width: 89px;
  height: 38px;
}

.assets_list_wrapper {
  min-height: 400px;
}

@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
